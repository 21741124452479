import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { AIRTABLE_API_KEY, BASE_ID, TABLE_NAME } from "./apiKeys";
import axios from "axios";

interface Record {
  fields: { [key: string]: any };
  id: string;
}

interface AirtableContextType {
  data: Record[];
}

const AirtableContext = createContext<AirtableContextType | undefined>(
  undefined
);

export const useAirtableData = () => {
  const context = useContext(AirtableContext);
  if (!context) {
    throw new Error("useAirtableData must be used within an AirtableProvider");
  }
  return context;
};

interface AirtableProviderProps {
  children: ReactNode;
}

export const AirtableProvider: React.FC<AirtableProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<Record[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`,
          {
            headers: { Authorization: `Bearer ${AIRTABLE_API_KEY}` },
          }
        );
        setData(response.data.records);
      } catch (error) {
        console.error("Error fetching data from Airtable:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <AirtableContext.Provider value={{ data }}>
      {children}
    </AirtableContext.Provider>
  );
};
