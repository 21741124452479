const Spinner = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        zIndex: 1,
      }}
    >
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
