interface TooltipProps {
  visible: boolean;
  x: number;
  y: number;
  text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ visible, x, y, text }) => {
  const style = {
    display: visible ? 'block' : 'none',
    left: `${x}px`,
    top: `${y}px`,
  };

  return (
    <div className="tooltip" style={style}>
      {text}
    </div>
  );
};

export default Tooltip;
