import { useState, useEffect } from "react";
import { useAirtableData } from "../context/AirtableContext";
import { handleCopyLink } from "../utils";
import { Link } from "react-router-dom";
import GoogleFormEmbed from "../components/GoogleFormEmbed";
import Tooltip from "../components/Tooltip";
import Spinner from "../components/Spinner";
import { FaChevronDown, FaChevronUp, FaLink, FaTimes } from "react-icons/fa";

const filterLabels = {
  topics: "topic",
  categories: "category",
};

const StyleGuide: React.FC = () => {
  const { data } = useAirtableData();
  const allCategories = data.flatMap((item) => item.fields.Category);
  const uniqueCategories = new Set(allCategories);
  type CategoryKeys = typeof uniqueCategories extends Set<infer U> ? U : never;

  const categoryIconColors: { [key in CategoryKeys]: string } = {
    Identity: "#A1C6E2",
    "Do Not Use": "#E88393",
    History: "#BFA3B7",
    Culture: "#FED78F",
    "Racial Stereotypes": "#B6BFCD",
    Geography: "#BCDBA9",
    Immigration: "#FABB8D",
    COVID: "#B6E5DA",
  };

  const [sortedData, setSortedData] = useState(data);
  const [sortOrder, setSortOrder] = useState("A-Z");
  const [filters, setFilters] = useState({
    topics: [] as string[],
    categories: [] as string[],
  });

  const [availableFilters, setAvailableFilters] = useState({
    topics: [] as string[],
    categories: [] as string[],
  });
  const [tooltip, setTooltip] = useState<{
    visible: boolean;
    x: number;
    y: number;
  }>({ visible: false, x: 0, y: 0 });
  const [searchTerm, setSearchTerm] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedMobile, setIsExpandedMobile] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  // dropdown
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (HashId: string) => {
    window.location.hash = HashId;
  };

  useEffect(() => {
    const handleHashChange = () => {
      const id = window.location.hash.substring(1).replace("/style-guide#", "");
      const element = document.getElementById(id);
      if (element) {
        const offset = 240; // Adjust this value for the desired padding
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    if (initialLoad && data.length > 0) {
      handleHashChange();
      setInitialLoad(false);
    }

    window.addEventListener("hashchange", handleHashChange);

    // Call the handler right away to handle the initial hash.
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [data, initialLoad]);

  useEffect(() => {
    let sortedArray = [...data];
    if (sortOrder === "A-Z") {
      sortedArray.sort((a, b) =>
        a.fields.Entries.toLowerCase().localeCompare(
          b.fields.Entries.toLowerCase()
        )
      );
    } else if (sortOrder === "Z-A") {
      sortedArray.sort((a, b) =>
        b.fields.Entries.toLowerCase().localeCompare(
          a.fields.Entries.toLowerCase()
        )
      );
    }
    setSortedData(sortedArray);
  }, [data, sortOrder]);

  useEffect(() => {
    const getUniqueValues = (key: string) =>
      Array.from(
        new Set(data.flatMap((item) => item.fields[key]).filter(Boolean))
      );
    setAvailableFilters({
      topics: getUniqueValues("Topic"),
      categories: getUniqueValues("Category"),
    });
  }, [data]);

  const handleFilterSelect = (type: "topics" | "categories", value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: prevFilters[type].includes(value)
        ? prevFilters[type].filter((item) => item !== value)
        : [...prevFilters[type], value],
    }));
  };

  const handleFilterDeselect = (
    type: "topics" | "categories",
    value: string
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: prevFilters[type].filter((filter) => filter !== value),
    }));
  };

  const getMatchingTerms = (termIds: string[]) => {
    return termIds?.map((termId) =>
      data.find((record) => record.id === termId)
    );
  };

  const filterData = () => {
    return sortedData.filter((term) => {
      const matchesSearch = term.fields.Entries.toLowerCase().includes(
        searchTerm.toLowerCase()
      );
      const matchesTopics =
        !filters.topics.length ||
        filters.topics.some((topic) => term.fields.Topic?.includes(topic));
      const matchesCategories =
        !filters.categories.length ||
        filters.categories.some((category) =>
          term.fields.Category?.includes(category)
        );
      return matchesSearch && matchesTopics && matchesCategories;
    });
  };

  const FilterButtons = ({ type }: { type: "topics" | "categories" }) => (
    <>
      <div className="side-panel-header">Filter by {filterLabels[type]}</div>
      <div className="side-panel-header-mobile">Filters</div>
      <div className="filter-buttons">
        {availableFilters[type].map((value: CategoryKeys) => (
          <button
            key={value}
            style={{
              backgroundColor: categoryIconColors[value],
              border: `1px solid ${filters[type].includes(value) ? "#000" : categoryIconColors[value]}`,
            }}
            className="category-icon"
            onClick={() => handleFilterSelect(type, value)}
          >
            {value}
            {filters[type].includes(value) && (
              <span
                className="close-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterDeselect(type, value);
                }}
              >
                <FaTimes />
              </span>
            )}
          </button>
        ))}
      </div>
    </>
  );

  const NoResultsMessage = () => (
    <div className="no-results">
      <h2>No results</h2>
      <p>
        Sorry, no results were found. Want to suggest the term for inclusion in
        the guide? Please complete this form:{" "}
      </p>
      <GoogleFormEmbed />
    </div>
  );

  return (
    <div className="style-guide">
      <div className="style-guide-page">
        <div className="style-guide-header">
          <button
            className="style-guide-dropdown-toggle"
            onClick={toggleDropdown}
          >
            <h2>
              Style Guide{" "}
              <span className="style-guide-dropdown-arrow">
                {isOpen ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </h2>
          </button>
          <div className="style-guide-dropdown">
            {isOpen && (
              <div className="style-guide-dropdown-menu">
                <Link
                  to="/guidance-resources#how-to-use"
                  className="donate-button"
                >
                  HOW TO USE THE GUIDE
                </Link>
                <a
                  href="https://forms.gle/UvyuamM2RkTiQqSe9"
                  className="donate-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTRIBUTE TO THE GUIDE
                </a>
              </div>
            )}
          </div>
          <div className={`search-bar ${isExpanded ? "expanded" : ""}`}>
            <input
              type="text"
              placeholder="Search style guide"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setIsExpanded(true)}
              onBlur={() => setIsExpanded(false)}
            />
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          </div>
          <div className={`search-bar-mobile`}>
            {isExpandedMobile && (
              <input
                type="text"
                placeholder="searched term"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onBlur={() => setIsExpandedMobile(false)}
              />
            )}
            <button onClick={() => setIsExpandedMobile((prev) => !prev)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          </div>
        </div>
        <div className="style-guide-content">
          <aside className="side-panel">
            <Link
              to="/guidance-resources#how-to-use"
              className="hero-button style-guide-button"
              style={{
                marginBottom: "1rem",
              }}
            >
              How to use the guide
            </Link>
            <div className="filter-section">
              <FilterButtons type="categories" />
            </div>
            <div className="sort-section">
              <div className="side-panel-header">Order terms</div>
              <div className="sort-options">
                <label>
                  <input
                    type="radio"
                    name="sortOrder"
                    value="A-Z"
                    checked={sortOrder === "A-Z"}
                    onChange={(e) => setSortOrder(e.target.value)}
                  />
                  Alphabetically A-Z
                </label>
                <label>
                  <input
                    type="radio"
                    name="sortOrder"
                    value="Z-A"
                    checked={sortOrder === "Z-A"}
                    onChange={(e) => setSortOrder(e.target.value)}
                  />
                  Z-A
                </label>
              </div>
            </div>
            <div className="add-section">
              <div className="side-panel-header">How to contribute</div>
              <p>
                Help us improve this guide! Do you have a new term you want to
                suggest? Please fill out this form:
              </p>
              <button
                className="donate-button"
                onClick={() =>
                  window.open("https://forms.gle/UvyuamM2RkTiQqSe9", "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                <span>Suggest a new term</span>
              </button>
            </div>
          </aside>
          <main className="main-content">
            <div className="style-guide-terms">
              {initialLoad ? (
                <Spinner />
              ) : filterData().length > 0 ? (
                filterData().map((term) => (
                  <div
                    key={term.id}
                    id={`${term.fields.HashId}`}
                    className="style-guide-term-card"
                  >
                    <div className="term-card-header">
                      <button
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                          handleCopyLink(term.fields.HashId, event, setTooltip)
                        }
                        className="copy-link-button term"
                      >
                        <FaLink />
                      </button>
                      <span className="term">{term.fields.Entries}</span>
                    </div>
                    <p className="description">{term.fields.Description}</p>
                    {getMatchingTerms(term.fields["See Also"])?.length > 0 && (
                      <p>
                        See also:{" "}
                        {getMatchingTerms(term.fields["See Also"]).map(
                          (matchedTerm, i, arr) => (
                            <span key={`matched-${i}`}>
                              <Link
                                to={`#${matchedTerm?.fields.HashId}`}
                                onClick={() => {
                                  // clear out searched terms
                                  setSearchTerm("");
                                  handleLinkClick(matchedTerm?.fields.HashId);
                                }}
                              >
                                {matchedTerm?.fields.Entries}
                              </Link>
                              {i < arr.length - 1 && "; "}
                            </span>
                          )
                        )}
                      </p>
                    )}
                    {term.fields.Category?.map(
                      (category: string, index: number) => (
                        <span
                          style={{
                            backgroundColor: categoryIconColors[category],
                            border: `1px solid ${categoryIconColors[category]}`,
                          }}
                          className="category-icon"
                          key={index}
                        >
                          {category}
                        </span>
                      )
                    )}
                  </div>
                ))
              ) : (
                <NoResultsMessage />
              )}
            </div>
          </main>
        </div>
        <Tooltip
          visible={tooltip.visible}
          x={tooltip.x}
          y={tooltip.y}
          text="Link copied!"
        />
      </div>
    </div>
  );
};

export default StyleGuide;
