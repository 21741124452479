import henryPhoto from "../assets/henry_fuhrmann_headshot_BW.png";
import { Link } from "react-router-dom";

const Dedication = ({
  link,
  donateText,
}: {
  link: string;
  donateText: string;
}) => {
  return (
    <section className="dedication-section">
      <h2>Dedication</h2>
      <div className="dedication-content">
        <div>
          <p>
            This guide is lovingly dedicated to the memory of Henry Fuhrmann, a
            longtime AAJA member and mentor. The
            <a
              href="https://www.latimes.com/california/story/2022-09-14/henry-fuhrmann-times-editor-dies"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Los Angeles Times assistant managing editor
            </a>{" "}
            pushed for equality through words. He was instrumental in making
            sure there was no hyphen in "Asian American."
          </p>
          <blockquote>
            “Those hyphens serve to divide even as they are meant to connect.
            Their use in racial and ethnic identities can connote an otherness,
            a sense that people of color are somehow not full citizens or fully
            American.”
            <p>
              — Henry Fuhrmann, in a post on{" "}
              <a
                href="https://consciousstyleguide.com/drop-hyphen-asian-american/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Conscious Style Guide
              </a>
            </p>
          </blockquote>
          {donateText === "More about the guide" ? (
            <Link to={link} className="donate-button">
              {donateText}
            </Link>
          ) : (
            <a
              href={link}
              className="donate-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {donateText}
            </a>
          )}
        </div>
        <div className="fuhrmann-image">
          <img src={henryPhoto} alt="Henry Fuhrmann" />
        </div>
      </div>
    </section>
  );
};

export default Dedication;
