import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Donate from "./Donate";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import { usePageTracking } from "../utils";

const Layout: React.FC = () => {
  usePageTracking();

  return (
    <div>
      <ScrollToTop>
        <Navbar />
      </ScrollToTop>
      <main>
        <Outlet />
      </main>
      <Donate />
      <Footer />
    </div>
  );
};

export default Layout;
