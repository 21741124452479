import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/long_logo.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      closeMobileMenu();
    }
  };

  const handleScroll = () => {
    if (mobileMenuOpen) {
      closeMobileMenu();
    }
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileMenuOpen]);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <NavLink to="/">
          <img src={logo} alt="Logo" />
        </NavLink>
      </div>
      <div
        ref={menuRef}
        className={`navbar-links ${mobileMenuOpen ? "open" : ""}`}
      >
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active-link" : "")}
          onClick={closeMobileMenu}
        >
          Home
        </NavLink>
        <NavLink
          to="/style-guide"
          className={({ isActive }) => (isActive ? "active-link" : "")}
          onClick={closeMobileMenu}
        >
          Style Guide
        </NavLink>
        <NavLink
          to="/guidance-resources"
          className={({ isActive }) => (isActive ? "active-link" : "")}
          onClick={closeMobileMenu}
        >
          Guidance and Resources
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? "active-link" : "")}
          onClick={closeMobileMenu}
        >
          About
        </NavLink>
      </div>
      <div className="navbar-hamburger" onClick={toggleMobileMenu}>
        {mobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;
