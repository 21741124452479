import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import LandingPage from "./pages/LandingPage";
import StyleGuide from "./pages/StyleGuide";
import GuidanceResources from "./pages/GuidanceResources";
import About from "./pages/About";
import { AirtableProvider } from "./context/AirtableContext";
import "./App.css";

const App: React.FC = () => {
  return (
    <AirtableProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage />} />
            <Route path="style-guide" element={<StyleGuide />} />
            <Route path="guidance-resources" element={<GuidanceResources />} />
            <Route path="about" element={<About />} />
          </Route>
        </Routes>
      </Router>
    </AirtableProvider>
  );
};

export default App;
