const Donate = () => {
  return (
    <div className="content">
      <section className="support-section">
        <p>
          This guide couldn’t have been produced without the volunteer hours and
          support of donors who keep AAJA running.
        </p>
        <a
          href="https://aaja-official.salsalabs.org/GeneralFundraisingForm/index.html"
          className="donate-button"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff" }}
        >
          Donate Now
        </a>
      </section>
    </div>
  );
};

export default Donate;
