import Dedication from "../components/Dedication";
import MarianPhoto from "../assets/Marian_headshot.jpg";
import BrianPhoto from "../assets/Brian_headshot.jpg";
import CeliaPhoto from "../assets/Celia_headshot.png";
import AudreyPhoto from "../assets/Audrey_headshot.png";
import ShellyPhoto from "../assets/Shelly_headshot.png";
import YiShenPhoto from "../assets/YL_headshot.jpg";
import NaomiPhoto from "../assets/Naomi_headshot.jpg";
import FeliciaPhoto from "../assets/felicia_headshot.jpg";

const teamMembers = [
  {
    name: "Marian Chia-Ming Liu",
    role: "Coleads",
    photo: MarianPhoto,
    bio: "AAJA’s national vice president of civic engagement and The Washington Post’s projects editor of special initiatives and partnerships. She has dedicated her career to the communities she represents — Asian, immigrant and female — and has covered everything from K-pop to anti-Asian attacks, interviewing everyone from Britney to BTS.",
    shortBio:
      "AAJA’s national vice president of civic engagement and The Washington Post’s projects editor of special initiatives and partnerships.",
    link: "https://www.linkedin.com/in/marianliu",
  },
  {
    name: "Brian Cleveland",
    role: "Coleads",
    photo: BrianPhoto,
    bio: "Deputy copy desk chief at The Washington Post. He has been heavily involved in writing style guides at The Post and at The Virginian-Pilot.",
    shortBio:
      "Deputy copy desk chief at The Washington Post. He has been heavily involved in writing style guides at The Post and at The Virginian-Pilot.",
    link: "https://www.linkedin.com/in/brianmcleveland",
  },
  {
    name: "Celia Wu",
    role: "Coleads",
    photo: CeliaPhoto,
    bio: "Longtime media executive and journalism evangelist who works at the intersection of publishing, reader revenue, product innovation and academia. She was an RJI Fellow ‘23-24, where she developed the first-ever Chrome extension of a newsroom’s style guide.",
    shortBio:
      "Longtime media executive and journalism evangelist who works at the intersection of publishing, reader revenue, product innovation and academia.",
    link: "https://www.linkedin.com/in/celiawu/",
  },
  {
    name: "Audrey Valbuena",
    role: "Production",
    photo: AudreyPhoto,
    bio: "UX and news designer at The Washington Post. She’s invested in telling more accessible, nuanced and beautiful stories, particularly around mixed-race identity and the Filipinx diaspora.",
    shortBio: "UX and news designer at The Washington Post.",
    link: "https://www.linkedin.com/in/audrey-valbuena/",
  },
  {
    name: "Shelly Cheng",
    role: "Production",
    photo: ShellyPhoto,
    bio: "Award-winning journalist and data visualization engineer, working on the elections team at The Associated Press. Driven by a passion for technology and design, she is committed to unraveling complex information and telling more nuanced stories that shed light on critical issues.",
    shortBio:
      "Award-winning journalist and data visualization engineer, working on the elections team at The Associated Press.",
    link: "https://www.linkedin.com/in/shellyscheng/",
  },
  {
    name: "Naomi Tacuyan Underwood",
    role: "HQ",
    photo: NaomiPhoto,
    bio: "Executive director of AAJA. She has nearly two decades of experience in nonprofit management, coalition and stakeholder engagement, legislative advocacy, and impact-oriented program development and management.",
    shortBio: "Executive Director of AAJA.",
    link: "https://www.linkedin.com/in/naomitacuyanunderwood/",
  },
  {
    name: "Felicia Chanco",
    role: "HQ",
    photo: FeliciaPhoto,
    bio: "Director of special initiatives at AAJA. She comes from 10+ years of experience as a producer of events and programs for Filipinx, AAPI, Arts and LGBTQIA+ organizations.",
    shortBio: "Director of special initiatives at AAJA.",
    link: "https://www.linkedin.com/in/feliciachanco/",
  },
  {
    name: "Yi-Shen Loo",
    role: "HQ",
    photo: YiShenPhoto,
    bio: "Special initiatives and communications manager at AAJA. She has worked on numerous oral history and community projects centering AAPI experiences.",
    shortBio: "Special initiatives and communications manager at AAJA.",
    link: "https://www.linkedin.com/in/yi-shenloo/",
  },
];

interface TeamMember {
  name: string;
  photo?: string;
  bio: string;
  shortBio: string;
  link: string;
  role: string;
}

interface TeamMemberCardProps {
  member: TeamMember;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ member }) => (
  <div className="card">
    <img
      src={
        member.photo ??
        `https://via.placeholder.com/150?text=${member.name.split(" ")[0]}`
      }
      alt={member.name}
    />
    <div className="member-name">{member.name}</div>
    <div className="member-bio">{member.bio}</div>
    <div className="member-short-bio">{member.shortBio}</div>
    <a
      href={member.link}
      target="_blank"
      className="bio-link"
      rel="noopener noreferrer"
    >
      LinkedIn
    </a>
  </div>
);

interface TeamSectionProps {
  title: string;
  role: string;
  teamMembers: TeamMember[];
}

const TeamSection: React.FC<TeamSectionProps> = ({
  title,
  role,
  teamMembers,
}) => (
  <section className="about-section">
    <h3>{title}</h3>
    <div className="team-section">
      {teamMembers
        .filter((member) => member.role === role)
        .map((member, index) => (
          <TeamMemberCard key={index} member={member} />
        ))}
    </div>
  </section>
);

const About: React.FC = () => {
  return (
    <div className="content">
      <div className="about-page">
        <h2 className="page-headline-left">About the Style Guide</h2>
        <p className="intro-text">
          This guide was the culmination of two years’ worth of hard work with a
          dedicated crew, composed of AAPI journalists across the industry and
          country, some of whom have also produced style guides for their own
          newsrooms. They overhauled the old guide, which had not been updated
          for more than a decade, added new entries and wrote out new
          definitions. Then, experts across academia and community work were
          tapped to double-check these entries for accuracy, and copy editors
          were tasked to edit them. Finally, a production team created a new
          living database.
        </p>
        <p className="intro-text">
          The AAJA Style Guide relies on volunteers to update the entries and
          keep it current and relevant. The committee will come together once a
          quarter. If you are interested in helping, please{" "}
          <a
            href="https://forms.gle/VFyz89fzMPCv2giE9"
            target="_blank"
            rel="noopener noreferrer"
          >
            fill out this form
          </a>{""}
          , and one of the committee co-chairs will get back to you! Thank you
          for your interest.
        </p>
        <TeamSection
          title="Co-leads"
          role="Coleads"
          teamMembers={teamMembers}
        />
        <TeamSection
          title="Production"
          role="Production"
          teamMembers={teamMembers}
        />
        <TeamSection
          title="AAJA HQ Project Management"
          role="HQ"
          teamMembers={teamMembers}
        />
        <section className="about-section">
          <h3>Committee</h3>
          <div className="committee name-box">
            <p>Kevin Chang Barnum</p>
            <p>Cody Hmelar</p>
            <p>Anita Hofschneider</p>
            <p>Naomi Ishisaka</p>
            <p>Jason Lalljee</p>
            <p>Kevin Lee</p>
            <p>Kavitha Rajagopalan</p>
            <p>Sameer Rao</p>
            <p>Pia Sarkar</p>
            <p>Shibani Shah</p>
            <p>Anika Varty</p>
            <p>April Xu</p>
          </div>
        </section>
        <section className="about-section">
          <h3>Special thanks to</h3>
          <div className="name-box four-columns-container special-thanks">
            <p>Michelle Boykins</p>
            <p>Mallory Carra</p>
            <p>Douglas S. Chan</p>
            <p>William Chang</p>
            <p>Ruoping Chen</p>
            <p>Allison Cho</p>
            <p>L.A. Chung</p>
            <p>Shilpa Davé</p>
            <p>William Diep</p>
            <p>Jeff Gu</p>
            <p>Dean Hamer</p>
            <p>Cristi Hegranes</p>
            <p>Kevin K. Hirano</p>
            <p>Ho-fung Hung</p>
            <p>Russell Jeung</p>
            <p>Corey Masao Johnson</p>
            <p>Daphne Kwok</p>
            <p>Kate Lee</p>
            <p>Myron Lee</p>
            <p>Michelle Li</p>
            <p>Jenny Liu</p>
            <p>Lori Matsukawa</p>
            <p>Terry Ao Minnis</p>
            <p>Jennifer Sano-Franchini</p>
            <p>Juhwan Seo</p>
            <p>Indah Setiawati</p>
            <p>Priyanka Vora</p>
            <p>Jay Wang</p>
            <p>Shu-wen Wang</p>
            <p>Joe Wilson</p>
            <p>Hinaleimoana Wong-Kalu</p>
            <p>Frank H. Wu</p>
            <p>John C. Yang</p>
            <p>Phil Yu</p>
          </div>
        </section>
      </div>
      <Dedication
        link="https://aaja-official.salsalabs.org/aaja-henryfuhrmann/index.html"
        donateText="donate in fuhrmann’s name"
      />
    </div>
  );
};

export default About;
