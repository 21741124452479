import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/long_logo.png";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-description">
          <div className="navbar-logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <p>
            This guide is meant for anyone who wants to better understand and
            more responsibly cover Asian American and Pacific Islander
            communities. Our diverse group is too often treated as a monolith
            and covered inaccurately or with stereotypes that can cause real
            harm. It is our hope that this guide can bring more nuance, accuracy
            and accountability to coverage of the AAPI population, which is made
            up of about 50 ethnic groups that speak more than 100 languages.
          </p>
          <p>
            <strong>Contact:</strong> styleguide@aaja.org
          </p>
        </div>
        <div className="footer-all-links">
          <div className="footer-links">
            <h4>Quick Links</h4>
            <Link to="/style-guide">Explore the Guide</Link>
            <a
              href="https://docs.google.com/forms/d/1f_h7VvQxPN19Vl72eVboF7ddCpuaa_Cjc0y-hllvVng/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Add to the Guide
            </a>
            <Link to="/about">About the Guide</Link>
          </div>
          <div className="footer-more-links">
            <h4>More AAJA</h4>
            <a
              href="https://www.aaja.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AAJA
            </a>
            <a
              href="https://aajastudio.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AAJA Studio
            </a>
            <a
              href="https://www.aaja.org/join"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join AAJA
            </a>
          </div>
          <div className="footer-social">
            <h4>Social</h4>
            <a
              href="https://twitter.com/AAJA"
              target="_blank"
              rel="noopener noreferrer"
            >
              X
            </a>
            <a
              href="https://www.instagram.com/aajaofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.facebook.com/AAJAHQ/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 AAJA STUDIO</p>
        <a
          href="https://www.aaja.org/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
